import React from "react";
import { DateTime } from "luxon";
import { SetterName, __PROMOTION_MAP__, useDynamicPromo } from "@/contexts/editor/DynamicPromoContext";
import { useModals } from "@/contexts/modals/ModalsContext";
import { useCompany } from "@/contexts/company/CompanyContext";

interface DynamicTextModalInterface { }

interface LabelInputProps {
	labelText: string;
	inputType: string;
	value: string | number;
	updateFunction: (value: string | number) => void;
	helperText?: string;
}

const DynamicTextModal: React.FC<DynamicTextModalInterface> = ({ }) => {
	const { showDynamicTextModal, setShowDynamicTextModal } = useModals();
	const { useTextClub } = useCompany();

	const {
		enabledPromos,
		updateCPFLimit,
		updateExpirationDate,
		updatePriceACada,
		updateApXUN,
		updateXOff,
		updateCompreEGanhe,
		updateLeveXPagueY,
		updateMaisX,
		updateCompreXMaisXLeve,
		updateInstallmentPrice,
		cpf_limit,
		expiration_date,
		price_a_cada,
		ap_x_UN,
		xOff_xUN,
		compre_e_ganhe,
		leveX_pagueY,
		maisX_Leve,
		compreX_MaisX_Leve,
		installmentPrice,
		toggleEnabled,
		promoVisibility,
		text_club,
		updateTextClub
	} = useDynamicPromo();

	const closeModal = () => {
		// setEnabledPromos(__PROMOTION_MAP__);
		setShowDynamicTextModal(false);
	}

	const handleOverlayClick = (e: React.MouseEvent): void => {
		e.stopPropagation();
		closeModal();
	};

	const formatMoney = (value: number): string => {
		// Adiciona zeros à esquerda se necessário,
		// e insere a vírgula na posição correta.
		const str = value.toString().padStart(3, '0');
		const finalStr = str.slice(0, -2) + ',' + str.slice(-2);
		return finalStr;
	};

	const updatePriceStartingFrom = (input: string): void => {
		const numericalInput = parseInt(input.replace(/[^\d]/g, ''));
		updatePriceACada(formatMoney(numericalInput));
	};

	const updateInstallmentValue = (input: string): void => {
		const numericalInput = parseInt(input.replace(/[^\d]/g, ''));
		updateInstallmentPrice({ ...installmentPrice, price: formatMoney(numericalInput) });
	};

	const updateInstallmentQuantity = (input: string): void => {
		const numericalInput = parseInt(input.replace(/[^\d]/g, ''));
		updateInstallmentPrice({ ...installmentPrice, quantity: numericalInput });
	};

	const updatePricePlus = (input: string): void => {
		const numericalInput = parseInt(input.replace(/[^\d]/g, ''));
		updateMaisX({ ...maisX_Leve, maisX: formatMoney(numericalInput) });
	};

	const updatePriceCompreXMaisXLeve = (input: string): void => {
		const numericalInput = parseInt(input.replace(/[^\d]/g, ''));
		updateCompreXMaisXLeve({ ...compreX_MaisX_Leve, maisX: formatMoney(numericalInput) });
	};

	const updateXOffValue = (value: string) => updateXOff({ ...xOff_xUN, xOff: parseInt(value) });
	const updateXOffXUN = (value: string) => updateXOff({ ...xOff_xUN, xUN: parseInt(value) });
	const updateXOffPrice = (input: string): void => {
		const numericalInput = parseInt(input.replace(/[^\d]/g, ''));
		updateXOff({ ...xOff_xUN, price: formatMoney(numericalInput) });
	};

	const updateCPFLimitValue = (value: string) => updateCPFLimit(parseInt(value));

	const updateApXUNValue = (input: string): void => {
		const numericalInput = parseInt(input.replace(/[^\d]/g, ''));
		updateApXUN({ ...ap_x_UN, price: formatMoney(numericalInput) });
	};

	const updateApXUNQuantity = (input: string): void => {
		const numericalInput = parseInt(input.replace(/[^\d]/g, ''));
		updateApXUN({ ...ap_x_UN, quantity: numericalInput });
	};

	const updateCompreEGanheValue = (value: string) => updateCompreEGanhe({ ...compre_e_ganhe, compre: parseInt(value) });
	const updateCompreEGanheItem = (value: string) => updateCompreEGanhe({ ...compre_e_ganhe, ganhe: value });

	const updateLeveXPagueYValue = (value: string) => updateLeveXPagueY({ ...leveX_pagueY, leve: parseInt(value) });
	const updateLeveXPagueYItem = (value: string) => updateLeveXPagueY({ ...leveX_pagueY, pague: parseInt(value) });

	const updateMaisXItem = (value: string) => updateMaisX({ ...maisX_Leve, leve: value });

	const updateCompreXMaisXLeveValue = (value: string) => updateCompreXMaisXLeve({ ...compreX_MaisX_Leve, compreX: parseInt(value) });
	const updateCompreXMaisXLeveItem = (value: string) => updateCompreXMaisXLeve({ ...compreX_MaisX_Leve, leve: value });

	return (
		<React.Fragment>
			<div
				className={`fixed inset-0 bg-black bg-opacity-30 transition-opacity duration-500 ease-in-out z-70 ${showDynamicTextModal ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
				onClick={handleOverlayClick}>
			</div>

			<div
				className={`fixed top-0 left-0 h-screen z-70 min-w-fit transition-all duration-300 ease-in-out ${showDynamicTextModal ? 'transform-none opacity-100 pointer-events-auto' : 'transform -translate-x-full opacity-0 pointer-events-none'}`}>

				<div className="flex flex-col justify-start w-[34rem] pt-4 h-full bg-white shadow-lg overflow-auto">
					<span className="text-xl text-center font-bold mb-4">Personalizar Texto Promocional</span>

					<div className="space-y-4 h-full overflow-y-auto px-10 pb-4">
						<div className="section">
							<div className="flex flex-col space-y-2">
								{
									enabledPromos.text_club_enabled && useTextClub ? (
										renderLabelInput("Texto Clube", 'string', text_club, updateTextClub, promoVisibility, toggleEnabled, "text_club_enabled", false, "Defina uma texto para o preço clube.")
									) : null
								}
								{
									enabledPromos.cpf_limit_enabled && (
										renderLabelInput('Limite CPF:', 'number', cpf_limit, updateCPFLimitValue, promoVisibility, toggleEnabled, "cpf_limit_enabled", true, 'Defina o limite de CPFs que podem aproveitar esta promoção.')
									)
								}
								{
									enabledPromos.expiration_date_enabled && (
										renderDateInput({
											labelText: "Data de Validade:",
											dateValue: expiration_date,
											updateFunction: (value: string | number) => updateExpirationDate(value as string),
											promoVisibility,
											toggleEnabled,
											activatableSetter: 'expiration_date_enabled',
											helperText: "Indique a data de validade do produto.",
										})
									)
								}
							</div>
						</div>

						<div className="section">
							<div className="flex flex-col space-y-2">
								{
									enabledPromos.price_a_cada_enabled && (
										renderLabelInput('Preço A Cada:', 'text', price_a_cada, updatePriceStartingFrom, promoVisibility, toggleEnabled, 'price_a_cada_enabled', true, 'Defina o preço mínimo a partir do qual uma promoção será aplicada.')
									)
								}

								{
									enabledPromos.ap_x_UN_enabled && (
										renderDualInput('A Partir de X UN:', 'Quantidade de Itens', 'number', ap_x_UN.quantity, updateApXUNQuantity, 'Preço do Item', 'text', ap_x_UN.price, updateApXUNValue, promoVisibility, toggleEnabled, 'ap_x_UN_enabled', 'Defina o valor mínimo de unidades necessárias para aproveitar esta promoção.')
									)
								}
							</div>
						</div>

						<div className="section">
							<div className="flex flex-col space-y-2">
								{
									enabledPromos.xOff_xUN_enabled && (
										renderTripleInput('X% Off:', 'Porcentagem de desconto', 'number', xOff_xUN.xOff, updateXOffValue, 'Quantidade itens', 'number', xOff_xUN.xUN, updateXOffXUN, 'Valor do Produto', 'text', xOff_xUN.price, updateXOffPrice, promoVisibility, toggleEnabled, 'xOff_xUN_enabled', 'Defina a porcentagem de desconto aplicado ao levar N itens.')
									)
								}
								{
									enabledPromos.compre_e_ganhe_enabled && (
										renderDualInput('Compre e Ganhe:', 'Na Compra de X Unidades', 'number', compre_e_ganhe.compre, updateCompreEGanheValue, 'Ganhe', 'text', compre_e_ganhe.ganhe, updateCompreEGanheItem, promoVisibility, toggleEnabled, 'compre_e_ganhe_enabled', 'Defina um brinde associado ao produto aplicado ao levar N itens.')
									)
								}
							</div>
						</div>

						<div className="section">
							<div className="flex flex-col space-y-2">
								{
									enabledPromos.leveX_pagueY_enabled && (
										renderDualInput('Leve X Pague Y:', 'Leve', 'number', leveX_pagueY.leve, updateLeveXPagueYValue, 'Pague', 'number', leveX_pagueY.pague, updateLeveXPagueYItem, promoVisibility, toggleEnabled, 'leveX_pagueY_enabled', 'Defina as quantidades para a promoção LeveX PagueY.')
									)
								}
								{
									enabledPromos.maisX_Leve_enabled && (
										renderDualInput('Mais XX,XX Leve:', 'Valor mínimo', 'text', maisX_Leve.maisX, updatePricePlus, 'Leve', 'text', maisX_Leve.leve, updateMaisXItem, promoVisibility, toggleEnabled, 'maisX_Leve_enabled', 'Defina um brinde associado ao produto aplicado ao levar mais XX,XX em compras.')
									)
								}
							</div>
						</div>

						<div className="section">
							<div className="flex flex-col space-y-2">
								{
									enabledPromos.compreX_MaisX_Leve_enabled && (
										renderTripleInput('Compre X, Mais X Leve:', 'Compre X', 'number', compreX_MaisX_Leve.compreX, updateCompreXMaisXLeveValue, 'Mais X', 'text', compreX_MaisX_Leve.maisX, updatePriceCompreXMaisXLeve, 'Leve', 'text', compreX_MaisX_Leve.leve, updateCompreXMaisXLeveItem, promoVisibility, toggleEnabled, 'compreX_MaisX_Leve_enabled', 'Defina um brinde associado ao produto aplicado ao levar mais XX,XX em compras.')
									)
								}
							</div>
						</div>

						<div className="section">
							<div className="flex flex-col space-y-2">
								{
									enabledPromos.installments_enabled && (
										renderDualInput('Preço Parcelado:', 'Quantidade de Parcelas', 'number', installmentPrice.quantity, updateInstallmentQuantity, 'Preço da Parcela', 'text', installmentPrice.price, updateInstallmentValue, promoVisibility, toggleEnabled, 'installments_enabled', 'Defina o preço das parcelas a serem aplicadas.')
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>

		</React.Fragment>
	);
}

export default DynamicTextModal;

function renderLabelInput(
	labelText: string,
	inputType: string,
	value: string | number,
	updateFunction: (value: any) => void,
	promoVisibility: Record<SetterName, boolean>,
	toggleEnabled: (id: SetterName, value: boolean) => void,
	activatableSetter: SetterName,
	useVisibility?: boolean,
	helperText?: string,
) {
	const checked = promoVisibility[activatableSetter];

	const toggle = () => {
		toggleEnabled(activatableSetter, !checked);
	}

	return (
		<div className="flex flex-col">
			<label className="flex items-center justify-between text-sm font-medium mb-1 ">
				<span className="text-lg">{labelText}</span>
				{
					useVisibility && (
						<label className="flex items-center gap-2 cursor-pointer no-text-highlight">
							Visível
							<input
								type="checkbox"
								checked={checked}
								onChange={toggle}
								className="no-text-highlight"
							/>
						</label>
					)
				}
			</label>
			<input
				type={inputType}
				value={value}
				onChange={(e) => updateFunction(e.target.value)}
				className="w-full border rounded-lg text-sm py-2 px-4"
			/>
			{helperText && <p className="text-xs text-gray-500 mt-1">{helperText}</p>}
		</div>
	);
}

function renderDualInput(
	labelText: string,
	labelLeft: string,
	typeLeft: string,
	valueLeft: string | number,
	updateFunctionLeft: (value: any) => void,
	labelRight: string,
	typeRight: string,
	valueRight: string | number,
	updateFunctionRight: (value: any) => void,
	promoVisibility: Record<SetterName, boolean>,
	toggleEnabled: (id: SetterName, value: boolean) => void,
	activatableSetter: SetterName,
	helperText?: string
) {
	const checked = promoVisibility[activatableSetter];

	const toggle = () => {
		toggleEnabled(activatableSetter, !checked);
	}

	return (
		<div className="flex flex-col">
			<label className="flex items-center justify-between text-sm font-medium mb-1 ">
				<span className="text-lg">{labelText}</span>
				<label className="flex items-center gap-2 cursor-pointer no-text-highlight">
					Visível
					<input
						type="checkbox"
						checked={checked}
						onChange={toggle}
						className="no-text-highlight"
					/>
				</label>
			</label>
			<div className="flex gap-4">
				<div className="w-1/2 flex flex-col">
					<label className="text-xs">{labelLeft}</label>
					<input
						type={typeLeft}
						value={valueLeft}
						onChange={(e) => updateFunctionLeft(e.target.value)}
						className="w-full border rounded-lg text-sm py-2 px-4"
					/>
				</div>
				<div className="w-1/2 flex flex-col">
					<label className="text-xs">{labelRight}</label>
					<input
						type={typeRight}
						value={valueRight}
						onChange={(e) => updateFunctionRight(e.target.value)}
						className="w-full border rounded-lg text-sm py-2 px-4"
					/>
				</div>
			</div>
			{helperText && <p className="text-xs text-gray-500 mt-1">{helperText}</p>}
		</div>
	);
}

function renderTripleInput(
	labelText: string,
	labelLeft: string,
	typeLeft: string,
	valueLeft: string | number,
	updateFunctionLeft: (value: any) => void,
	labelMiddle: string,
	typeMiddle: string,
	valueMiddle: string | number,
	updateFunctionMiddle: (value: any) => void,
	labelRight: string,
	typeRight: string,
	valueRight: string | number,
	updateFunctionRight: (value: any) => void,
	promoVisibility: Record<SetterName, boolean>,
	toggleEnabled: (id: SetterName, value: boolean) => void,
	activatableSetter: SetterName,
	helperText?: string
) {
	const checked = promoVisibility[activatableSetter];

	const toggle = () => {
		toggleEnabled(activatableSetter, !checked);
	}

	return (
		<div className="flex flex-col items-start justify-center">
			<label className="w-full flex items-center justify-between text-sm font-medium mb-1 ">
				<span className="text-lg">{labelText}</span>
				<label className="flex items-center gap-2 cursor-pointer no-text-highlight">
					Visível
					<input
						type="checkbox"
						checked={checked}
						onChange={toggle}
						className="no-text-highlight"
					/>
				</label>
			</label>
			<div className="flex gap-4">
				<div className="flex flex-col">
					<label className="text-xs">{labelLeft}</label>
					<input
						type={typeLeft}
						value={valueLeft}
						onChange={(e) => updateFunctionLeft(e.target.value)}
						className="w-full border rounded-lg text-sm py-2 px-4"
					/>
				</div>
				<div className="flex flex-col">
					<label className="text-xs">{labelMiddle}</label>
					<input
						type={typeMiddle}
						value={valueMiddle}
						onChange={(e) => updateFunctionMiddle(e.target.value)}
						className="w-full border rounded-lg text-sm py-2 px-4"
					/>
				</div>
				<div className="flex flex-col">
					<label className="text-xs">{labelRight}</label>
					<input
						type={typeRight}
						value={valueRight}
						onChange={(e) => updateFunctionRight(e.target.value)}
						className="w-full border rounded-lg text-sm py-2 px-4"
					/>
				</div>
			</div>
			{helperText && <p className="text-xs text-gray-500 mt-1">{helperText}</p>}
		</div>
	);
}

const renderDateInput = ({
	labelText,
	dateValue,
	updateFunction,
	promoVisibility,
	toggleEnabled,
	activatableSetter,
	helperText,
}: Omit<LabelInputProps, 'inputType' | 'value'> & {
	dateValue: string | null, activatableSetter: SetterName,
	promoVisibility: Record<SetterName, boolean>,
	toggleEnabled: (id: SetterName, value: boolean) => void,
}) => {
	const checked = promoVisibility[activatableSetter];

	const toggle = () => {
		toggleEnabled(activatableSetter, !checked);
	}

	return (
		<div className="flex flex-col">
			<label className="text-sm font-medium mb-1 flex justify-between">
				<span className="text-lg">{labelText}</span>
				<label className="ml-2 cursor-pointer no-text-highlight">
					Visível
					<input
						type="checkbox"
						checked={checked}
						onChange={toggle}
						className="ml-1"
					/>
				</label>
			</label>
			<input
				type="date"
				value={formatDate(dateValue)}
				onChange={(e) => updateFunction(e.target.value)}
				className="w-full border rounded-lg text-sm py-2 px-4"
			/>
			{helperText && <p className="text-xs text-gray-500 mt-1">{helperText}</p>}
		</div>
	);
}

function formatDate(date: string | null): string {
	const now = DateTime.now().toISODate() as string;
	if (!date) return now;

	let dt = DateTime.fromISO(date);

	if (!dt.isValid) {
		console.error('Data inválida: ' + dt.invalidExplanation);
		return now;
	}

	return dt.toISODate() || now;
}
